import { postFetch } from '../../api'

type LoginParams = {
  email: string
  password: string
}

type LoginResult = {
  token: string
  type: string
  brand?: string
}

export async function login(params: LoginParams): Promise<LoginResult> {
  return await postFetch<LoginParams, LoginResult>('/login', params)
}