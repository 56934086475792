import React from 'react'
import { Link } from 'react-router-dom'
import { Function0, Function1 } from 'lodash'
import { VelocityTransitionGroup } from 'velocity-react'

import * as Components from '../../components'
import * as validators from '../../utils/validators'
import i18n from '../../i18n'

export const Links = () => {
  const [count, setCount ] = React.useState(0)

  React.useEffect(() => {
    if (count > 7) {
      window.location.href = 'https://download.kassatka.online/uppdater_139.apk';
      setCount(0)
    }
  }, [count]);

  return (
    <>
      <p>
        <Link className="recover-link" to="/recover">
          {i18n.labels.forgetPassword}
        </Link>
      </p>

      <p>
        {i18n.labels.isNoAccount}{" "}
        <Link className="register-link" to="/register">
          {i18n.labels.register}
        </Link>
      </p>

      <p>
        <a className="hidden-link" href="https://download.kassatka.online/uppdater_139.apk">Нажимая </a>
        кнопку «Вход», вы соглашаетесь с <span onClick={() => setCount((v) => v + 1)}>условиями</span> &nbsp;
        <a
          href="https://reports.kassatka.online/offer/offer.pdf"
          className="offer-link"
        >
          оферты
        </a>
      </p>
    </>
  )
}

type LoginFormProps = {
  isUnauthorized: boolean
  isUnknownClientError: boolean
  isUnknownServerError: boolean
  submitIsEnabled: boolean
  isInvalidEmail: boolean
  isInvalidPassword: boolean
  email: string
  password: string
  isRemember: boolean
  onLogin: Function0<void>
  onChangePassword: Function1<string, void>
  onChangeEmail: Function1<string, void>
  onChangeIsRemember: Function1<boolean, void>
}

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const {
    onLogin, isUnauthorized, isUnknownClientError, isUnknownServerError, email, password, isRemember, submitIsEnabled,
    onChangeEmail, onChangePassword, onChangeIsRemember, isInvalidEmail, isInvalidPassword
  } = props;
  return (
    <form
      className="login-form"
      onSubmit={e => {
        e.preventDefault();
        onLogin();
      }}
    >
      <VelocityTransitionGroup
        enter={{ animation: "slideDown" }}
        leave={{ animation: "slideUp" }}
      >
        {isUnauthorized && (
          <div className="qpos-login-alert">
            {i18n.messages.invalidEmailOrPassword}
          </div>
        )}

        {isUnknownClientError && (
          <div className="qpos-login-alert">
            {i18n.messages.unknownClientError}
          </div>
        )}

        {isUnknownServerError && (
          <div className="qpos-login-alert">
            {i18n.messages.unknownServerError}
          </div>
        )}
      </VelocityTransitionGroup>

      <Components.Input
        id="username"
        label={i18n.labels.email}
        onChange={onChangeEmail}
        text={email}
        autoFocus
        required
        showErrorText={isInvalidEmail}
        errorText={i18n.messages.invalidEmail}
        tabIndex={1}
        validator={validators.isEmail}
        placeholder="Введите email"
      />

      <Components.Input
        type="password"
        id="password"
        label={i18n.labels.password}
        onChange={onChangePassword}
        text={password}
        showErrorText={isInvalidPassword}
        errorText={i18n.messages.invalidPassword}
        required
        tabIndex={2}
        validator={validators.isPassword}
        placeholder="Введите пароль"
      />

      <div className="form-item">
        <Components.Checkbox
          className="brand-checkbox"
          label={i18n.labels.rememberMe}
          checked={isRemember}
          tabIndex={3}
          onChange={onChangeIsRemember}
        />
      </div>

      {submitIsEnabled ? (
        <Components.Button
          className="brand-btn brand-btn-primary login-button"
          noMargin
          label={i18n.buttons.login}
          onClick={onLogin}
          submit
          noIcon
        />
      ) : (
        <Components.Button
          className="brand-btn brand-btn-primary login-button"
          noMargin
          label={i18n.buttons.login}
          noIcon
          disabled
        />
      )}
    </form>
  )
}