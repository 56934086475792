import React from "react";
import cookie from 'js-cookie'

import * as Components from "../../components";
import * as validators from "../../utils/validators";
import { AuthError, isResponseWithAuthError } from '../../api'
import * as selectors from '../../utils/errorDecoders'
import { Links, LoginForm } from './_Components'
import { login } from './_api'
import { InfoBanner } from '../../components/InfoBanner';
// import { TariffBanner } from '../../components/TariffBanner';

type LoginProps = {

}

type LoginState = {
  email: string
  password: string
  isRemember: boolean
  error: AuthError | null
}

export class LoginPage extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isRemember: false,
      error: null,
    };
  }

  onLogin = async () => {
    const { email, password, isRemember } = this.state;
    const submitIsEnabled = validators.isEmail(this.state.email) && validators.isPassword(this.state.password);

    if (!submitIsEnabled) return;

    try {
      this.setState({ error: null });
      const result = await login({ email, password })
      // show discount modal
      localStorage.removeItem('tariffDiscountModal');
      if(isRemember) {
        cookie.set('TOKEN', result.token, { expires: 7 })
        cookie.set('TYPE', result.type, { expires: 7 })
        if (result.brand) {
          cookie.set('BRAND', result.brand, { expires: 7 })
        }
      } else {
        cookie.set('TOKEN', result.token)
        cookie.set('TYPE', result.type)
        if(result.brand) {
          cookie.set('BRAND', result.brand);
        }
      }

      document.location.href = "/";
    } catch (e: any) {
      if (isResponseWithAuthError(e)) {
        this.setState({ error: e.authError })
      }
    } finally {
      this.setState({ password: '' });
    }
  }

  render() {
    const { email, password, isRemember, error } = this.state;
    const isInvalidEmail = selectors.isInvalidEmail(error);
    const isInvalidPassword = selectors.isInvalidPassword(error);
    const isUnauthorized = selectors.isUnauthorized(error);
    const isUnknownClientError = selectors.isUnknownClientError(error);
    const isUnknownServerError = selectors.isUnknownServerError(error);
    const submitIsEnabled =
      validators.isEmail(this.state.email) &&
      validators.isPassword(this.state.password);

    return (
      <div className="app">
        <div className="panel-body max-w-600">
          <Components.Brand />
          <LoginForm
            isUnauthorized={isUnauthorized}
            isUnknownClientError={isUnknownClientError}
            isUnknownServerError={isUnknownServerError}
            submitIsEnabled={submitIsEnabled}
            isInvalidEmail={isInvalidEmail}
            isInvalidPassword={isInvalidPassword}
            email={email}
            password={password}
            isRemember={isRemember}
            onLogin={this.onLogin}
            onChangePassword={(password: string) => this.setState({ password })}
            onChangeEmail={(email: string) => this.setState({ email })}
            onChangeIsRemember={(isRemember: boolean) => this.setState({ isRemember })}
          />
          {/*<InfoBanner />*/}
          {/*<TariffBanner />*/}
          <Links />
        </div>
      </div>
    );
  }
}